<template>
  <div class="SaveCenter document-content"></div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.SaveCenter {
}
</style>